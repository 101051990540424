@import 'src/commons/styles/abstracts';

.RentalAccountAnnouncement {
  header {
    @include padding-top($xl);
    @include padding-right($m, $xl);
    @include padding-left($m, $xl);
  }

  h3 {
    @include order-2;
    @include margin-top($l);
    color: color(brandred);
    font-weight: 500;
  }

  dl {
    @include margin-bottom($l);
  }

  ul {
    list-style-type: none;
  }

  .Card__body {
    @include padding-top($m);

    @include bp($bp-1) {
      padding-bottom: size($s, $bp-1) !important;
    }

    @include bp($bp-2) {
      padding-bottom: size($l, $bp-2) !important;
    }
  }
}
