@import 'src/commons/styles/abstracts';

.FeedbackStars {
  margin: 0;
  margin-bottom: 4rem;
  @include bp($bp-2) {
    @include margin-bottom($l);
  }

  &__group {
    border: none;
  }

  &__legend {
    width: 100%;
    font-weight: normal;
    line-height: 24px;
    font-size: 14px;
    color: #343434;
    margin-bottom: 0.5rem;

    @include bp($bp-2) {
      font-weight: normal;
      line-height: 32px;
      font-size: 16px;
      color: #343434;
      margin-bottom: 0.5rem;
    }
  }

  &__stars--flex {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: fit-content;
  }

  &__label {
    display: inline-block;
    margin-right: 2.7rem; // exactly fits iphone 5's viewport which is the smallest viewport supported
  }

  &__error {
    @include label-1;
    @include margin($xs, 0, 0, 0);
    color: color(brandred);
  }

  input:focus + label {
    position: relative;
    outline: none;
    &:after {
      content: '';
      position: absolute;
      top: -0.8rem;
      bottom: -0.8rem;
      right: -1.2rem;
      left: -1.2rem;
      border: 0.4rem solid $indicator;
      border-radius: 0.4rem;
    }
  }
}
