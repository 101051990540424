@import 'src/commons/styles/abstracts';

.Landing {
  &__heading {
    font-weight: 300;
    color: color(grey);
    @include margin-bottom($l);

    &-welcome {
      @include label-1;
      @include margin-bottom($xs);
      display: block;
    }
    &-name {
      @include order-1;
      display: block;
    }
  }

  &__paragraph {
    @include order-2;
    @include margin-bottom($xxl);
    font-weight: 300;
    color: color(grey, 85);
  }

  &__tile-grid {
    display: grid;
    grid-gap: size($xxs, $bp-2);
    grid-template-columns: repeat(1, 1fr);
    justify-items: center;
    margin: 0 auto;
    position: relative;
    @include padding-top($xxl);
    @include padding-bottom($xxl);

    @include bp($bp-2) {
      display: grid;
      justify-items: normal;
      grid-gap: size($xxs, $bp-2);
      grid-template-columns: repeat(2, 1fr);
      max-width: 74rem;
    }
  }

  &__announcement {
    position: relative;
    @include padding-bottom($xxl);
    &:after {
      content: '';
      position: absolute;
      display: block;
      @include top($xxl);
      left: 50%;
      bottom: 0;
      width: 100vw;
      transform: translateX(-50%);
      z-index: -1;
      background: color(warmgrey);
    }
  }

  &__head {
    @include padding-top($xl);
    @include padding-right($m, $xl);
    @include padding-left($m, $xl);
  }

  &__TeaserTile {
    svg {
      max-width: 28rem;
      max-height: 13rem;
    }
  }
}
