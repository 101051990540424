@import 'src/commons/styles/abstracts';

.WhatsNewAnnouncement {
  header {
    @include padding-top($xl);
    @include padding-right($m, $xl);
    @include padding-left($m, $xl);
  }

  h3 {
    @include order-2;
    @include margin-top($l);
    color: color(brandred);
    font-weight: 500;
  }

  &__navigation {
    display: flex;
    flex-direction: column;

    @include bp($bp-2) {
      flex-direction: row;
    }
  }

  &__ack-button {
    text-align: center;

    &--50 {
      @include bp($bp-2) {
        width: 50%;
      }
    }

    &--100 {
      @include bp($bp-2) {
        width: 100%;
      }
    }
  }

  .Card__body {
    @include bp($bp-1) {
      padding-bottom: size($s, $bp-1) !important;
    }

    @include bp($bp-2) {
      padding-bottom: size($l, $bp-2) !important;
    }
  }

  .Badge {
    border-radius: 3.6rem;
  }

  .Badge__icon-before {
    top: -4px;
  }

  &__details-link {
    @include padding-bottom($l);
    width: auto;
    text-align: center;

    @include bp($bp-2) {
      @include padding-top($s);
      width: 50%;
    }
  }
}
