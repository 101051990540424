@import 'src/commons/styles/abstracts';

.CookieLayover {
  &__body {
    //dynamically added to the document.body to prevent the background scrolling
    position: fixed;
  }
  &__window {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(color(black), 0.7);
    z-index: 90;
  }

  &__modal {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    z-index: 92;
    max-height: 100%;
    @include padding-top($xxl);
    @include padding-left($l);
    @include padding-right($l);
    @include padding-bottom($xxl);

    overflow-y: auto;
    overscroll-behavior-y: contain; //stop the parent content behind the model scrolling - nice browsers which support it

    @include bp($bp-2) {
      margin: 0 auto;
      width: 62.5rem;
      @include padding($xxl, $xxl, $xxl, $xxl);
    }
    background-color: white;
    &--expanded {
      // no extra settings needed now
    }
  }

  &__heading {
    font-weight: 500;
    @include order-2;
    color: color(grey, 100);
    margin-bottom: 2.4rem;
  }

  &__content {
    font-weight: normal;
    @include copy-2;
    color: color(grey, 85);
  }

  &__impressumLink {
    display: block;
  }

  &__list {
    @include margin-top($xxl);
    list-style: none;
    padding-left: 0;
    li {
      display: flex;
      align-content: center;
      @include padding-bottom($xl);
    }
  }

  &__checkIconContainer {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 1.2rem;
  }
  &__check-icon {
    margin: auto auto;
    stroke: color(grey, 85);
  }
  &__cookiesWhichAreRequiredText {
    padding-top: 0.2rem;
  }

  &__buttonContainer {
    display: flex;
    flex-direction: column;
    @include padding-top($xxxl);
    @include bp($bp-2) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }

  &__confirmAllButton {
    @include margin-top($l);
    @include bp($bp-2) {
      margin-top: 0;
      @include margin-left($l);
    }
  }

  &__overCookiesArticle {
    section p {
      @include margin-bottom($l);
    }
    header {
      font-weight: 700;
      @include margin-bottom($l);
    }
    footer {
      @include margin-top($xxl);
    }
  }
}
