@import 'src/commons/styles/abstracts';

.DataProtection {
  &__p {
    @include copy-2;
    color: color(grey, 100);
    &:not(:first-child) {
      @include margin-top($xl);
    }
  }

  .Accordion__item {
    &:first-of-type {
      @include margin-top($xxxl);
    }
    &:last-child {
      @include margin-bottom($xxxl);
    }
  }

  &__address {
    font-style: normal;
    @include margin-top($m);
    ul {
      list-style: none;
    }
  }

  &__heading {
    font-weight: bold;
    @include copy-2;
    @include margin-top($xl);
    @include margin-bottom($l);

    &:first-child {
      margin-top: 0;
    }
  }

  &__dl {
    padding-left: 1.7rem;
    dt {
      @include margin-top($xl);
      @include margin-bottom($xs);
    }
    dd {
      display: list-item;
      list-style-position: outside;
      @include margin-left($m);
      @include padding-left($m);
      @include margin-bottom($xs);
    }
  }

  &__dt {
    margin-top: 0 !important;
  }
}
