/** Components **/
@import 'src/commons/styles/List';

/** Base */
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Roboto', sans-serif;
  color: color(grey);
}

label {
  font-size: 1.4rem;
  line-height: 2rem;
}

button,
input,
textarea,
select {
  font-family: 'Roboto', sans-serif;
}

p:not(:first-of-type) {
  @include margin-top($s);
}

p,
small {
  max-width: 75rem;
}

small {
  display: block;
}

p {
  .Link {
    text-decoration: underline;
  }
}
