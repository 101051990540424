@import 'src/commons/styles/abstracts';

.Footer {
  border-top: 0.4rem solid color(brandgreen);

  &__menu {
    border-top: 0.4rem solid color(lightgreen, 50);
    background: color(warmgrey);
  }

  &__menu-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    @include content-area;
    @include padding-top($l);
    @include padding-bottom($l);
    @include bp($bp-2) {
      flex-direction: row;
    }
  }

  &__menu-item {
    &:not(:last-child) {
      @include margin-right(0, $xl);
      @include margin-bottom($m, 0);
    }
    a {
      color: color(grey);
      @include label-1;
      text-decoration: none;

      @include padding-bottom($xxs);

      &:focus {
        border-bottom: 0.4rem solid $indicator;
      }

      &--selected,
      &:hover {
        border-bottom: 0.1rem solid $indicator;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: space-between;
    @include content-area;
    @include padding-top($m, $l);
    @include padding-bottom($m, $l);
  }

  &__wwlogo {
    svg {
      height: 2.8rem;
    }

    &__link {
      border-bottom: 0.4rem solid transparent;

      &:focus {
        border-color: $indicator;
      }
    }
  }

  &__icons {
    display: flex;
    align-items: flex-end;

    a {
      border-bottom: 0.4rem solid transparent;

      &:focus {
        border-color: $indicator;
      }
    }

    &:last-child a {
      margin-left: 2rem;
    }
  }
}
