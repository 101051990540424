@import 'src/commons/styles/abstracts';

.Popover {
  position: relative;

  &--visible {
    .Popover__arrow {
      transform: rotate(-180deg);
    }
  }

  &__arrow {
    transition: transform 150ms ease-in-out;
  }

  &__button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    position: relative;
    cursor: pointer;

    &::-moz-focus-inner {
      border: 0;
    }

    & > *:first-child {
      @include margin-right($xs);
    }

    &:after {
      content: '';
      position: absolute;
      top: -0.8rem;
      bottom: -0.8rem;
      right: -1.2rem;
      left: -1.2rem;
    }

    @include padding-bottom($xxs);
    margin-bottom: size($xs, $bp-1) * -1;
    border-bottom: 0.4rem solid transparent;

    &:focus {
      border-color: $indicator;
    }
  }

  &__popover {
    position: absolute;
    background: color(white);
    border: 0.1rem solid color(grey, 15);
    width: 20rem;
    box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.0834749);
    border-radius: 0.4rem;
    right: 0;
    transform: translateY(100%);
    bottom: size($xxs, $bp-1) * -1;
    z-index: 9999;
    @include padding($l, $l, $xl);
    @include bp($bp-2) {
      bottom: size($s, $bp-1) * -1;
    }
  }

  &__user {
    & > * {
      display: flex;
      @include label-1;
    }

    dt {
      color: color(grey, 85);
      @include margin-bottom($xxs);
    }

    dd {
      font-weight: bold;
    }
  }

  &__menu-list {
    list-style-type: none;
  }

  li {
    @include padding-top($l);
  }

  path {
    stroke: color(grey);
  }

  &__rotate {
    svg {
      transform: rotate(180deg);
    }
  }

  &__menu-list .Link__icon-wrapper-before {
    width: 2.4rem;
    height: 2.4rem;
  }
}
