@import 'src/commons/styles/abstracts';

.BypassMenu {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;

  &__item {
    pointer-events: none;
    a {
      display: inline-flex;
      height: 5rem;
      pointer-events: none;

      justify-content: center;
      align-items: center;

      position: absolute;

      padding: 10px;

      border-radius: 0.4rem;
      box-shadow: 0rem 0.2rem 0.5rem rgba(0, 0, 0, 0.08);
      border: 0.1rem solid color(grey, 50);
      background: color(black);
      color: color(white);

      top: -100rem;
      left: 0;
      right: 0;
      z-index: 99999;

      opacity: 0.8;

      &:focus {
        top: 1.5rem;
        left: 2rem;
        right: 2rem;
      }

      @include bp($bp-2) {
        width: 20rem;
        &:focus {
          top: 1.5rem;
          left: 1.5rem;
          right: auto;
        }
      }
    }
  }
}
