@import 'src/commons/styles/abstracts';

.List {
  list-style-position: outside;
  padding-left: 1.7rem;
  @include margin-left($m);
  @include margin-top($xs);
  li {
    @include padding-left($m);
    @include margin-bottom($xs);
  }
}
