@import 'src/commons/styles/abstracts';

.Announcement {
  &--broken,
  &--locked {
    h3 {
      color: color(brandred);
    }
  }

  &--reminder,
  &--video {
    h3 {
      color: color(brandgreen);
    }
  }

  header {
    @include padding-top($xl);
    @include padding-right($m, $xl);
    @include padding-left($m, $xl);
  }

  &__form {
    header {
      svg {
        top: -4px;
      }
    }
  }

  li {
    list-style: none;
  }

  .Announcement__list {
    &--classic {
      @include margin-bottom($m);
    }

    &--inline {
      li {
        display: inline;

        &:not(:last-child) {
          &:after {
            content: ' · ';
          }
        }
      }
    }
  }

  dt {
    color: color(brandgreen) !important;
  }

  h3 {
    @include order-2;
    font-weight: 500;
    @include margin-top($l);
  }

  p {
    &:not(:last-of-type) {
      margin-top: 0;
      @include margin-bottom($m);
    }
  }

  .Button {
    @include margin-top($xxl);
  }

  .Card__body {
    @include padding-top($l);

    @include bp($bp-1) {
      padding-bottom: size($s, $bp-1) !important;

      .Button {
        margin-bottom: size($xs, $bp-1);
      }
    }

    @include bp($bp-2) {
      padding-bottom: size($l, $bp-2) !important;
    }
  }

  &__description {
    white-space: pre-wrap;
    @include margin-bottom($m);
    max-width: none;

    &-link {
      @include margin-top($s);
    }
  }
}
