@import 'src/commons/styles/abstracts';

.Feedback {
  height: 100%;
  &__wrapper-desktop {
    @include bp($bp-2) {
      max-height: 90vh;
      overflow-x: hidden;
      padding: 0 3.2rem 0 3.2rem;
    }
  }

  &__feedbackheading--desktop {
    display: none;
    @include bp($bp-2) {
      display: block;
    }
  }

  &__form {
    margin: 0;
    padding: 0 1rem 3.2rem 1rem;
    @include bp($bp-2) {
      padding: 3.2rem 0 3.2rem 0;
    }
  }

  &__button-submit {
    width: 100%;
    @include margin-top(0, $xs);
    @include bp($bp-2) {
      width: initial;
    }
  }

  &__info {
    font-weight: normal;
    @include copy-2;
    color: color(grey, 85);
    padding-bottom: 2rem;
  }

  &__label-textarea {
    display: inline-block;
    margin-bottom: 2rem;
    @include copy-2;
  }

  &__stars {
    margin-bottom: 4.8rem;
    &:focus {
      outline: 1px solid color(linkblue);
      outline-offset: 0.4rem;
    }
  }

  &__confirmation {
    height: 276px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    @include bp($bp-2) {
      max-height: 90vh;
      overflow-x: hidden;
      padding: 0 3.2rem 0 3.2rem;
    }

    h4 {
      margin-top: 2.4rem;
      font-weight: bold;
      @include order-2;
      text-align: center;
      color: color(grey, 100);
    }
  }

  &__icon-close {
    border: none;
    border-bottom: 0.4rem solid transparent;
    @include padding-bottom($xxs);
    display: none;
    cursor: pointer;

    @include bp($bp-2) {
      display: inline-block;
      position: absolute;
      right: 24px;
      top: 24px;
    }

    &:focus {
      border-bottom-color: $indicator;
    }

    background: none;
    span {
      outline: none;
    }
    svg {
      outline: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: -0.8rem;
      bottom: -0.8rem;
      left: -1.2rem;
      right: -1.2rem;
    }
  }

  &__icon-letter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include bp($bp-2) {
      display: none;
    }
  }

  &__overlay-container {
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__icon-letter--large {
    display: none;
    @include bp($bp-2) {
      display: inline-block;
    }
  }

  &__finish--overlay {
    margin-top: 2.4rem;
    @include order-2;
    font-weight: bold;
    text-align: center;
  }

  &__finish--inline {
    display: none;

    @include bp($bp-2) {
      margin-top: 2.4rem;
      @include order-2;
      font-weight: bold;
      text-align: center;
    }
  }

  &__feedbackheading-desktop {
    display: none;
    @include bp($bp-2) {
      display: block;
      position: sticky;
      top: 0;
      z-index: 10;
      background-color: white;
      padding: 0;
    }
  }

  &__error {
    @include copy-2;
    @include margin($xs, 0, 0, 0);
    color: color(brandred);
  }
}
