@import 'src/commons/styles/abstracts';

.RentalAccount {
  &__adjustment {
    & p {
      @include margin-top($xl);
      max-width: none !important;
    }
  }

  &__container {
    @include margin-top($xxl);
    @include margin-bottom($xl);
  }

  &__contract {
    display: flex;

    &-icon {
      @include padding-top($xs);
      @include margin-right($l, $xl);
    }
  }

  &__contract-data {
    @include padding-right($s);
    @include bp($bp-2) {
      @include padding-right($xxxl);
    }

    dl {
      display: flex;
      flex: 1;
      flex-direction: column;
    }

    dt {
      @include label-1;
    }

    dd {
      @include order-2;
    }
  }

  &__pending-container {
    display: flex;
    flex-direction: column;

    dl {
      margin-bottom: 0 !important;
    }

    @include bp($bp-2) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__paymentdescr {
    @include label-1;
    color: color(grey, 85);
  }

  &__feedback {
    display: flex;
    justify-content: center;
    align-items: center;

    @include margin-top($xs);
    @include margin-bottom($xl);

    &--big {
      @include order-2;
      @include bp($bp-2) {
        font-size: 2rem;
      }
    }

    &--green {
      color: color(brandgreen);
    }
  }

  &__feedback-icon {
    @include margin-right($s);
  }

  &__link {
    margin: 0;

    @include bp($bp-2) {
      @include margin-top($s);
    }
  }

  &__hyperlink-box {
    @include margin-top($l);
    @include padding-top($xl);
    border-top: 0.1rem solid color(grey, 15);

    a {
      text-decoration: underline;
    }
  }

  &__faq-open-point {
    @include copy-1;
    @include padding($xl, 0, $s);
  }

  &__paragraph {
    @include copy-2;
    color: color(grey);

    &:not(:last-child) {
      @include margin-bottom($xl);
    }
  }

  &__small {
    @include label-1;
  }

  &__details {
    @include margin-bottom($l);
    @include bp($bp-2) {
      display: flex;
    }
  }

  &__detail-item {
    @include margin-bottom($m);

    @include bp($bp-2) {
      @include margin-right($m);
      margin-bottom: 0;
    }

    dt {
      @include copy-1;
      @include margin-bottom($s);
      color: color(grey);
      font-weight: bold;
    }

    dd {
      ul {
        @include copy-2;
        color: color(grey, 85);
        list-style: none;
      }
    }
  }

  &__update {
    display: flex;
    @include bp($bp-2) {
      align-items: center;
    }

    dt {
      @include margin-right($xxs);
      margin-bottom: 0;
    }
  }

  &__update-icon {
    display: flex;
    @include margin-right($s);
    align-items: center;
  }

  &__update-content {
    display: flex;
    flex-direction: column;
    @include copy-2;
    color: color(grey, 100);

    .Data__key:first-of-type {
      margin-bottom: 0;
      color: color(grey, 100);
      @include copy-2;
    }

    dd:first-of-type {
      @include copy-2;
    }

    & > * {
      @include copy-2;
      color: color(grey, 100);
      line-height: 1;
    }

    @include bp($bp-2) {
      flex-direction: row;
    }
  }

  &__Payment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;

    dt {
      margin: 0;
    }

    dd {
      font-weight: bold;
      align-self: flex-start;
      @include bp($bp-2) {
        align-self: normal;
      }
    }

    @include bp($bp-2) {
      flex-direction: row;
    }

    &--big {
      & > * {
        @include order-2;
      }

      @include margin-top($l);
      @include padding-top($xl);
      border-top: 0.1rem solid color(grey, 15);
    }

    &--small {
      & > * {
        @include copy-1;
      }

      &:not(:last-child) {
        @include margin-bottom($m);
      }
    }
  }

  &__StatusLink {
    &:not(:last-child) {
      @include margin-bottom($m);
    }
  }

  &__last-payment-wrapper {
    .Card__body {
      dl {
        dt {
          @include copy-2;
        }
      }
    }
  }

  h4 {
    @include copy-2;
    font-weight: bold;
  }

  &__p {
    @include margin-top($s);
    @include margin-bottom($s);
    @include copy-2;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__bankinfo {
    @include copy-2;
    display: flex;

    dt {
      font-weight: bold;
      @include margin-right($xxs);
    }
  }

  .Announcement {
    @include margin-bottom($xl);
  }

  .Accordion__item li a {
    text-decoration: underline;
  }

  &__video {
    h4 {
      @include margin-top($s);
      @include copy-1;
      font-weight: normal;
    }

    .Card__body {
      padding-bottom: 2.8rem !important;
    }

    &--seen {
      strong {
        margin-bottom: 0;
      }

      .Accordion__item-content {
        padding: 2rem;
      }
    }
  }

  &__announcement {
    position: relative;
    @include padding-bottom($xxl);
    &:after {
      content: '';
      position: absolute;
      display: block;
      @include top($xxl);
      left: 50%;
      bottom: 0;
      width: 100vw;
      transform: translateX(-50%);
      z-index: -1;
      background: color(warmgrey);
    }
    &:empty {
      padding-bottom: 0;
    }
  }

  &__faq {
    h4 {
      @include margin-top($s);

      & + p {
        margin-top: 0;
      }
    }
  }
}
