@import 'src/commons/styles/abstracts';

.FeedbackHeading {
  &__h3 {
    font-weight: normal;
    @include order-2;
    color: color(grey, 100);
    padding-top: 4rem;
  }

  &__h4 {
    font-weight: normal;
    @include order-2;
    color: color(grey, 100);
    margin-top: 1.2rem;
    @include bp($bp-2) {
      display: none;
    }
  }

  &__paragraph {
    font-weight: normal;
    @include copy-2;
    color: color(grey, 100);
    @include margin-top($xl);
  }

  &__icon {
    display: none;
    @include bp($bp-2) {
      cursor: pointer;
      order: 1;
      display: inline-block;
    }
  }

  &__close-box {
    right: 0;
    top: 2.4rem;
    position: absolute;
    display: none;
    @include bp($bp-2) {
      display: inherit;
    }
  }

  &__close {
    @include margin-right($l);
    @include margin-bottom($s);

    position: relative;
    cursor: pointer;

    border: none;
    border-bottom: 0.4rem solid transparent;
    @include padding-bottom($xxs);

    background: none;
    span {
      outline: none;
    }
    svg {
      outline: none;
    }

    &:focus {
      border-color: $indicator;
    }

    &:before {
      content: '';
      position: absolute;
      top: -0.8rem;
      bottom: -0.8rem;
      left: -1.2rem;
      right: -1.2rem;
    }
  }
}
