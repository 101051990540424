@import 'src/commons/styles/abstracts';

.FeedbackBox {
  margin-bottom: 6rem;
  @include bp($bp-2) {
    margin-bottom: 0;
  }

  & &__collapsible {
    padding: 2rem 0 0 0;
  }

  &__modal {
    display: none;

    @include bp($bp-2) {
      display: block;
      position: fixed;
      width: 50rem;
      background-color: white;
      left: 50%;
      outline: 0;
      height: auto;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 92;

      &-scrim {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(color(black), 0.7);
        z-index: 90;
      }
    }
  }

  &__feedback--mobile {
    @include content-area;
    @include bp($bp-2) {
      display: none;
    }
  }

  &__feedback--desktop {
    display: none;
    @include bp($bp-2) {
      display: block;
      z-index: 10;
    }
  }

  &__collapsible-title {
    display: flex;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: white;
  }
}
